/*
 * General utils for managing cookies in Typescript.
 */



function _setCookie(name: string, val: string, date: Date | null = null) {

    // Set it
    const expire = date ? ` expires=${date.toUTCString()}; ` : '';
    const cookie = `${encodeURIComponent(name)}=${encodeURIComponent(val)};${expire}path=/; SameSite=None; Secure; domain=.${location.host || location.hostname}`;
    document.cookie = cookie;
}

function _getCookie(name: string): string | null {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
        const d = parts.pop();
        if (d) {
            return d.split(";").shift() as string;
        }
    }
    return null;
}

export function _deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
}

const getDate = (time?: number|Date|null):Date|null => {
    let date: Date | null = new Date();

    if (typeof time === 'number') {
        if (time === 0)
            time = new Date(date.getTime() + (7 * 24 * 60 * 60 * 1000));
        else
            time = new Date(date.getTime() + time);
    }
    return time || null;
}

type CookieValue = {
    value: string, date: string|null
}

export const RadiaczLocalStorageEventName = 'RadiaCzLocalStorageEvent';

export type RadiaczLocalStorageEvent = CustomEvent<string>


class OverStorage {
    protected fake: { [path: string]:  CookieValue } = { };
    protected useCookie = false;
    protected storage: Storage | null = null;
    protected canAddListener = false;
    protected change = false;



    constructor() {
        const name = /^https?:\/\/home\./.test(location + '')? 'thome' : 'tradiacz';
        const d = new Date().getTime() + '';

        if ('localStorage' in window && window['localStorage'] != null) {
            this.storage = localStorage;
        }
        if ('onstorage' in window || 'onStorage' in window)
        {

            try {
                const changeListener = (event:StorageEvent) => {

                    if (this.change)
                        return;

                    if (event.key)
                    {
                        window.dispatchEvent(new CustomEvent(RadiaczLocalStorageEventName, {
                            detail:  event.key
                        }) as RadiaczLocalStorageEvent)
                    }
                }

                window.addEventListener('storage', changeListener);
                this.canAddListener = true;

            }
            catch (e) {

            }

        }




        _setCookie(name, d);
        this.useCookie = _getCookie(name) === d;

        this.initGet();
        this.initSet();
        this.initRemove();

        delete this.initRemove;
        delete this.initSet;
        delete this.initGet

    }

    addListener = (e:(e:RadiaczLocalStorageEvent)=>void)=>{
        if (this.canAddListener)
            window.addEventListener(RadiaczLocalStorageEventName, e as EventListener);
        else
            return false;
    }
    removeListener = (e:(e:RadiaczLocalStorageEvent)=>void)=>{
        if (this.canAddListener)
            window.removeEventListener(RadiaczLocalStorageEventName, e as EventListener);
        else
            return false;
    }

    protected  initRemove =  ()=> {
        if (this.storage) {
            const storage = this.storage;
            this.remove = (name:string) => {
                this.change = true;
                storage.removeItem(name);
                this.change = false;
            }
        }
        else if (this.useCookie) {
            this.remove = (name:string) => {
                _deleteCookie(name);
            }
        }
        else {
            this.remove = (name:string) => {
                delete this.fake[name];
            }
        }
    }

    remove = (name: string):void => {
        throw new Error('nex');
    }

    protected initSet = () => {

        if (this.storage) {
            const storage = this.storage;
            this.set = (name, value, time) => {
                this.change = true;
                const date = getDate(time);
                if (!date || date > new Date())
                    storage.setItem(name, JSON.stringify({value, date: date ? date.toUTCString() : null}));
                else
                    storage.removeItem(name);
                this.change =false;
            }

        } else if (this.useCookie) {
            this.set = (name, value, time) => {
                _setCookie(name, value, getDate(time));
            }
        } else {
            this.set = (name, value, time) => {
                const date = getDate(time);
                if (!date || date > new Date())
                    this.fake[name] = {value, date: date ? date.toUTCString() : null};
                else
                    delete this.fake[name];
            }
        }
    }
    /**
     *
     * @param name
     * @param value
     * @param time
     */
    set = (name: string, value: string, time?: number|Date|null):void => {

        throw new Error('nex');
    }

    protected initGet = () => {
        if (this.storage)
        {
            const storage = this.storage;
            this.get = (name)=> {
                this.change = true;
                let ret:string|null = null;
                let data = storage.getItem(name);
                if (data) {
                    try {
                        const data2 = JSON.parse(data) as CookieValue;
                        if (!data2.date || new Date(data2.date) > new Date() )
                            ret = data2.value;
                        else
                            storage.removeItem(name);

                    }
                    catch(e) {}

                }
                this.change = false;
                return ret;
            }
        }
        else if (this.useCookie)
        {
            this.get = (name)=> {
                return _getCookie(name);
            }
        }
        else {
            this.get = (name) => {
                const data2 = this.fake[name];
                if (data2)
                {
                    if (!data2.date || new Date(data2.date) > new Date() )
                        return data2.value;
                    else
                        delete this.fake[name];
                }
                return null;
            }
        }
    }

    get = (name: string): null | string => {
        throw new Error('nex');
    }
}

const storage = new OverStorage();


export const {get: getCookie, set: setCookie, remove: deleteCookie, addListener: addStorageListener, removeListener: removeStorageListener } = storage;