
export const PLAY = "PLAY";
export const LOADING = "LOADING";
export const STOP = "STOP";

export type Status = typeof PLAY | typeof LOADING | typeof STOP;

export const LARGE = 'large';
export const SMALL = 'small';
export const EXTRASMALL = 'extra-small';
export const EXTRASMALL2 = 'extra-small2';
export const IMAGEADVURL = 'radiacz_IMAGEADVURL'
export const ADVURL = 'radiacz_ADVURL';
export const ADVSTOP = 'radiacz_ADVSTOP';
export const ADVSTART = 'radiacz_ADVSTART';
export const ADVCHANGESIZE = 'radiacz_ADVCHANGESIZE';

const hasQuerySelector = !!document.querySelectorAll

let UseHomeScript1 = false

let hledaniUsehome = (hasQuerySelector ? document.querySelectorAll('script'): document.getElementsByName('script'));
for (let i = 0; i < hledaniUsehome.length; i++) {
    // @ts-ignore
    UseHomeScript1 = UseHomeScript || /home.radia.cz\/.*?iframe.radiacz-player\.js$/.test(hledaniUsehome[i].src + '');
}

export const UseHomeScript = UseHomeScript1

export const UseHome = ((document.location+'').indexOf('usehome=1') > -1 ||  'home.radia.cz' === document.location.hostname )? 1 : undefined;

export type Size = typeof LARGE | typeof SMALL | typeof EXTRASMALL | typeof EXTRASMALL2;

export const MESSAGELOCATION = 'MESSAGELOCATION';
export const MESSAGEJUMP = 'MESSAGEJUMP';

export type MessageType = MessageLocation | MessageJump

export type MessageLocation = {
    type: typeof MESSAGELOCATION,
    uuid: string,
    domain: string
}

export type  MessageJump  = {
    type: typeof MESSAGEJUMP
    jump: true
}

export const MakeMessage = (data:any) => {
    return {
        type: 'radiaczplayer',
        message: data
    }
}
export const FromMessage = (data:any)=> {
    if (!data)
        return false

    if (typeof data === 'object' && data.type === 'radiaczplayer' && 'message' in data) {
        return data.message
    }

    return false
}
