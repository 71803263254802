import {
    ADVURL,
    ADVSTART, ADVSTOP,
    EXTRASMALL,
    EXTRASMALL2,
    LARGE,
    Size,
    SMALL,
    UseHome,
    UseHomeScript, MakeMessage, FromMessage,  IMAGEADVURL
} from "./App/PlayerReducerSetting";

const getVast = (()=>{
    let VAST:Promise<typeof import('./App/Advertisement/VastUtils')>| null = null;

    const RetFunc = (): Promise<typeof import('./App/Advertisement/VastUtils')>| null => {
        if (typeof window['Promise'] === 'undefined') {
            return null
        }
        if (VAST === null)
        {
            VAST = import('./App/Advertisement/VastUtils')
        }
        return VAST
    }

    if (typeof window['Promise'] === 'undefined') {
        const script = document.createElement('script')
        script.onload = () => {
            setTimeout(RetFunc, 1000)
        }
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js'
        document.head.appendChild(script)
    } else {
        RetFunc();
    }

    return RetFunc

})();



(() => {
    const d = new Date()
    d.setTime(compiledtime)
    console.error('Radia.cz iframe: ', d)
})()

import {setCookie, getCookie} from "./App/Utils/Storage";
import parseUrl from 'parse-url';

let entries = Object.entries

let from = Array.from

if (!entries) {
    entries = function <T>(o: { [s: string]: T } | ArrayLike<T>): ([string, T][]) {
        const output: ([string, T][]) = [];
        for (const i in o) {
            output.push([i, o[i]])
        }
        return output
    }
}
if (!from) {
    from = function <T>(o: ArrayLike<T>): T[] {
        const output: T[] = []
        for (let i = 0; i < o.length; i++) {
            output.push(o[i])
        }
        return output
    }
}


let style: HTMLStyleElement | null = document.createElement('style');
style.innerHTML = `
    .iframe-radiacz-large { width: 300px !important; height: 300px !important; padding: 0; border: 0; frameBorder: 0; display: 'block'; overflow: 'hidden'; }
    .iframe-radiacz-small { width: 300px !important; height: 170px !important; padding: 0; border: 0; frameBorder: 0; display: 'block'; overflow: 'hidden'; }
    .iframe-radiacz-extra-small { width: 275px !important; height: 75px !important; padding: 0; border: 0; frameBorder: 0; display: 'block'; overflow: 'hidden';}
    .iframe-radiacz-extra-small2 { width: 145px !important; height: 50px !important; padding: 0; border: 0; frameBorder: 0; display: 'block'; overflow: 'hidden';}
`
document.head.appendChild(style);
style = null;

let AdvInit = () => {

    window.addEventListener('message', (ev) => {
        const message = FromMessage(ev.data)

        if (typeof message === 'object') {

            switch (message.type) {
                case ADVURL:
                    const source = ev.source as Window
                    const urls: string[] = []

                    const catchError = (e) => {

                        source.postMessage(MakeMessage({
                            //client,
                            urls,
                            response: false
                        }), "*")
                    }
                    const VAST = getVast()
                    if (VAST === null ) {
                        catchError(null)
                    } else {
                        VAST.then(i => {
                            const client = new i.VASTClient();

                            const parsedUrl = parseUrl(message.url);
                            parsedUrl['search'] += (parsedUrl['search'] ? '&' : '') + 'time=' + (new Date().getTime());
                            const url = parsedUrl['protocol'] + '://' + parsedUrl['resource'] + parsedUrl['pathname'] + '?' + parsedUrl['search'];


                            client.getParser().on('VAST-resolving', function (a) {
                                urls.push(a.url)
                            })

                            client.get(url, {}).then(response => {
                                source.postMessage(MakeMessage({
                                    urls,
                                    vastResponse: response
                                }), "*")
                            }).catch(catchError)
                        }).catch(catchError)
                    }
                    break
                case IMAGEADVURL:
                    const {URL, event} = message.message
                    console.error('radia.cz event(iframe)', event, URL)
                    const img = new Image()
                    img.onload = function () {

                        console.error('radia.cz event(iframe image loaded)', event, URL)
                    }
                    img.src = URL
                    break
            }
        }

    })
}

const add = (iframe: HTMLIFrameElement, comment: Comment) => {



    iframe.allow = "";
    iframe.scrolling = "no"
    iframe.allow = "autoplay";
    //iframe.referrerPolicy = "unsafe-url";
    if (iframe.sandbox?.add)
        iframe.sandbox.add('allow-popups', 'allow-scripts', 'allow-top-navigation', 'allow-pointer-lock', 'allow-same-origin');

    let size = comment.size;

    switch (size) {
        case 'small':
            iframe.className = 'iframe-radiacz-small';
            break;
        case 'large':
            iframe.className = 'iframe-radiacz-large';
            break;
        case 'extra-small2':
            iframe.className = 'iframe-radiacz-extra-small2';
            break;
        default:
            iframe.className = 'iframe-radiacz-extra-small';
            break;
    }


    iframe.style.borderRadius = '5px';
    iframe.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.25)';


    if (comment.advmodal) {
        let oldSet: null | CSSStyleDeclaration = null;
        const bcIframe = document.createElement('iframe');

        let onScroll = () => {
        }
        let onScrollFrame: ReturnType<typeof requestAnimationFrame> | null = null
        let onScrollInterval: ReturnType<typeof setInterval> | null = null

        //const div = document.createElement('div');
        //div.className = 'radia'
        //div.className = 'iframe-radiacz-background';
        window.addEventListener('message', (ev) => {

            const message = FromMessage(ev.data)
            if (message === false)
                return


            if (message !== ADVSTART && message !== ADVSTOP)
                return


            const wframe = from(document.getElementsByTagName('iframe')).filter((c: HTMLIFrameElement) => {
                return c.contentWindow === ev.source && c === iframe;
            })[0];

            //console.log(message, wframe)

            if (!wframe)
                return
            let zIndex = 4000;

            (()=> {
                try {
                    const foundedZIntex = from(document.querySelectorAll('body *'))
                        .map(a => parseFloat(window.getComputedStyle(a).zIndex))
                        .filter(a => !isNaN(a))
                        .sort()
                        .pop();

                    if (foundedZIntex)
                        zIndex = foundedZIntex + 2;
                }
                catch (e) {

                }
            })();

            switch (message) {

                case ADVSTART:
                    oldSet = Object.assign({}, entries(iframe.style).reduce((acumulator, current) => {
                        if (/position|left|top|zIndex|transform|width|height/.test(current[0])) {
                            acumulator[current[0]] = current[1];
                        }
                        return acumulator;
                    }, {})) as CSSStyleDeclaration;


                    bcIframe.className = iframe.className;
                    wframe.className = 'iframe-radiacz-large';

                    /*
                    Object.assign(iframe.style, {
                        position: 'fixed',
                        left: 'calc(50vw - 150px)',
                        top: 'calc(50vh - 150px)',
                        zIndex: `${zIndex}`
                    } as CSSStyleDeclaration);
                    */
                    const oldPos = 1

                    let oldleft: number, oldtop: number;

                    onScroll = () => {
                        const {left, top} = bcIframe.getBoundingClientRect();

                        if (oldleft !== left || oldtop !== top) {
                            oldleft = left;
                            oldtop = top;
                            Object.assign(iframe.style, {
                                left: left + 'px',
                                top: top + 'px',
                            } as CSSStyleDeclaration);
                        }
                        if (onScrollFrame) {
                            cancelAnimationFrame(onScrollFrame);
                            onScrollFrame = requestAnimationFrame(onScroll);
                        }
                    }
                    if (typeof window['requestAnimationFrame'] !== 'undefined')
                        onScrollFrame = requestAnimationFrame(onScroll);
                    else
                        onScrollInterval = setInterval(onScroll, 1000 / 60);

                    window.addEventListener('scroll', onScroll);
                    wframe.parentNode?.insertBefore(bcIframe, wframe);
                    onScroll();
                    Object.assign(wframe.style, {
                        position: 'fixed',
                        zIndex: `${zIndex}`
                    } as CSSStyleDeclaration);

                    wframe.contentWindow?.postMessage(MakeMessage('radiacz_SIZE_' + LARGE), "*");
                    break;

                case ADVSTOP:
                    if (onScrollFrame) {
                        cancelAnimationFrame(onScrollFrame);
                        onScrollFrame = null;
                    }
                    if (onScrollInterval) {
                        clearInterval(onScrollInterval);
                        onScrollInterval = null;
                    }
                    wframe.style.position = '';
                    window.removeEventListener('scroll', onScroll);
                    bcIframe.parentNode?.removeChild(bcIframe);
                    Object.assign(wframe.style, oldSet);
                    wframe.className = 'iframe-radiacz-' + size;
                    wframe.contentWindow?.postMessage(MakeMessage('radiacz_SIZE_' + size), "*");
                    break

            }
        }, false);
    }

    const paramsObject = {
        size,
        hash: __webpack_hash__,
        usehome: UseHome,
        adv: comment.adv ? 1 : 0,
        parent: 1
    };


    switch (true) {
        case (document.referrer || '').indexOf('seznam.cz') > -1 && (document.location.host || document.location.hostname || '').indexOf('femina.cz') > -1:
            const d = new Date();
            d.setTime(d.getTime() + (3600000));
            setCookie('rczfs1', "1", d);

    }

    if (getCookie("rczfs1") === "1") {
        paramsObject.adv = 0;
    }

    const params = '?' + entries(paramsObject).filter(i => i[1] !== undefined).map(i => `${i[0]}=${encodeURIComponent(i[1] + '')}`).join('&')


    if (UseHomeScript) {
        iframe.src = 'https://home.radia.cz/simpleplayer2/' + params;
    } else {
        iframe.src = 'https://radia.cz/simpleplayer2/' + params;
    }
    AdvInit()
    AdvInit = () => {

    }

}
type Init = { radiacz: { size?: Size, adv?: boolean, advmodal?: boolean } } | null;

type Comment = {
    node: Node,
    size: Size,
    adv: boolean,
    advmodal: boolean,

};

const makeComment = (node: Node, data: any): Comment | undefined => {

    if (typeof data === 'string') {
        try {
            data = JSON.parse(data) as Init;
        } catch (e) {
            return;
        }
    }


    if (data && data.radiacz) {
        let size = data.radiacz.size;

        switch (size) {
            case LARGE:
            case SMALL:
            case EXTRASMALL:
            case EXTRASMALL2:
                break;
            default:
                size = LARGE;
        }

        let advmodal = size === EXTRASMALL || size === EXTRASMALL2;
        let adv = (size !== EXTRASMALL && size !== EXTRASMALL2)

        if (data.radiacz.adv !== undefined) {
            adv = data.radiacz.adv;
        }

        if (data.radiacz.advmodal !== undefined) {
            advmodal = data.radiacz.advmodal;
        }

        return {
            node,
            size,
            adv,
            advmodal,

        };

    }
}

const radiacziframe = () => {
    try {

        var x = document.evaluate('//comment()', document, null, XPathResult.ANY_TYPE, null),
            comment = x.iterateNext();

        // <!-- radiacz(extra-small|small|large) -->

        const comments: Comment[] = [];

        while (comment) {

            let data = makeComment(comment, comment.textContent);
            if (data)
                comments.push(data)
            comment = x.iterateNext();
        }

        comments.forEach(item => {
            const iframe = document.createElement('iframe');
            item.node.parentNode?.insertBefore(iframe, item.node);
            add(iframe, item);
        })
    } catch (e) {

    }
}
// @ts-ignore
window.radiaczplayer = (el: Node, object?: object) => {

    try {
        const comment = makeComment(el, object);
        if (comment) {
            const iframe = document.createElement('iframe');

            el.appendChild(iframe);
            add(iframe, comment);
        }

    } catch (e) {
        console.log('radia.cz player', e)
    }
}


if (document.readyState === 'complete' || document.readyState === 'interactive') {
    radiacziframe();
} else {
    const volani = (ev) => {
        document.removeEventListener('DOMContentLoaded', volani);
        radiacziframe();
    };
    document.addEventListener('DOMContentLoaded', volani);
}